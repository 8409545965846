import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button, { ButtonVariant } from "../components/Button";
import Layout from "../components/Layout";
import Section from "../components/Section";
import Title, { TitleVariant } from "../components/Title";
import Quote from "../components/Quote";
import { useLayout } from "../components/Layout/layout-context";

const Formations = () => {
  const [setShowModal] = useLayout();

  return (
    <Layout
      title="Formations"
      metaTitle="Formation en UX Design"
      description="Gagner en compétences en UX design grâce à une formation sur mesure certifiée Qualiopi, portant sur votre produit ou service"
    >
      <p className="mt-main">
        Nous souhaitons accompagner des experts sur les compétences qui feront
        d'eux des professionnels incontournables dans leurs secteurs dès demain.
        <br />
        <br />
        Grâce à nous, les apprenants peuvent mettre en place des projets centrés
        utilisateur pour améliorer leur produit ou service, monter
        individuellement en compétence et pour certains, devenir UX
        designer.Nous souhaitons accompagner des experts sur les compétences qui
        feront d'eux des professionnels incontournables dans leurs secteurs dès
        demain.
        <br />
        <br />
        Grâce à nous, les apprenants peuvent mettre en place des projets centrés
        utilisateur pour améliorer leur produit ou service, monter
        individuellement en compétence et pour certains, devenir UX designer.
      </p>

      <Section
        title="🎓 Besoin d'une formation&nbsp;?"
        titleVariant={TitleVariant.h3}
      >
        <p className="mt-main">
          Quelques heures ou quelques jours vous permettrons de monter en
          compétences et d'atteindre vos objectifs.
        </p>
        <ul className="mt-7">
          <li className="my-3">
            🎯 Intégrez une démarche centrée utilisateur dans votre buisness
          </li>
          <li className="my-3">
            🎯 Ajoutez des compétences à forte valeur ajoutée à vos employés
          </li>
          <li className="my-3">
            🎯 Appliquez directement vos apprentissages sur votre site grâce au
            projet fil rouge
          </li>
          <li className="my-3">
            🎯 Bénéficiez d'une formation gratuite grâce aux aides auxquelles
            vous avez droit
          </li>
        </ul>
      </Section>

      <Section title="Programme">
        <div className="py-4 mt-main" id="programme">
          <Title
            variant={TitleVariant.h4}
            text="Mettez l'expérience client au cœur de votre stratégie e-commerce"
            className="text-center"
          />
          <p className="mt-main">
            Découvrez toutes nos formations sur l'expérience client
          </p>
          <ul className="mt-4 lg:mt-6">
            <li>🍩 Les bases de l'expérience client</li>
            <li>🍩 Créer un discours de marque</li>
            <li>🍩 Adapter l'architecture de l'information et la navigation</li>
            <li>🍩 Améliorer l'accessibilité de son site</li>
            <li>
              🍩 Optimiser vos pages principales (page produit, tunnel d'achat,
              page d'accueil...) grâce aux connaissances en psychologie.
            </li>
          </ul>
          <Button
            variant={ButtonVariant.secondary}
            to="/formation/experience-client"
            text="Développer ma stratégie"
            className="mx-auto mt-main"
          />
        </div>

        <div className="py-4 mt-main">
          <Title
            variant={TitleVariant.h4}
            text="Faites de vos salariés des futurs UX designers !"
            className="text-center"
          />
          <p className="mt-main">
            Faites-les monter en compétences en UX design sur un de vos propres
            projets
          </p>
          <ul className="mt-4 lg:mt-6">
            <li>🍩 Introduction à l'UX design</li>
            <li>🍩 Effectuer vos propres recherches utilisateur</li>
            <li>
              🍩 Animer des ateliers pour profiter de la créativité de votre
              équipe et de vos utilisateurs
            </li>
            <li>🍩 Concevoir un prototype pour anticiper des évolutions</li>
            <li>🍩 Recueillir l'avis d'utilisateur sur vos interfaces</li>
          </ul>
          <Button
            variant={ButtonVariant.secondary}
            to="/formation/ux-design"
            text="Former mon équipe"
            className="mx-auto mt-main"
          />
        </div>
      </Section>
      <Section title="Financement" titleVariant={TitleVariant.h2}>
        <p className="mt-main">
          En tant qu'organisme de formation, nous vous faisons bénéficier
          d'aides pour effectuer nos formations, celles-ci pouvant aller jusqu'à
          100% du prix de la formation.
          <br />
          <br />
          💌 Contactez-nous afin d'être accompagné sur la création de dossier et
          les demandes de subvention des OPCO.
          <br />
          <br />
          Toutes les entreprises peuvent faire bénéficier d'une formation à
          leurs employés.
          <br />
          Toutes les entreprises n'en profitent pas, et chaque année ce sont des
          centaines d'heures de formation gratuites qui sont perdues.
          <br />
          <br />
          N'attendez plus et faites profiter à vos employés d'une montée en
          compétence.
        </p>
      </Section>
      <Section title="Durée">
        <p className="mt-main">
          Nous proposons des formations allant d'une demi- journée à plusieurs
          semaines.
        </p>
        <Title
          variant={TitleVariant.h4}
          text="Notre conseil 💡"
          className="my-6 text-center"
        />
        <p>
          Nous recommandons de choisir une formation pouvant s'étaler sur
          plusieurs semaines, afin d'intégrer une partie de mise en pratique sur
          vos interfaces et de bénéficier de nos retours pour vous améliorer.
        </p>
      </Section>
      <Section title="Les avis sur nos formations">
        <Quote
          className="mt-main"
          picture={
            <StaticImage
              src="../images/photos/romain.png"
              alt="photo de Romain"
              className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
            />
          }
          nameAndTitle={
            <p>
              Romain Nauthonnier
              <br />
              Cofondateur et PDG de COMBIGO
            </p>
          }
          quote={
            <p className="italic">
              “Lors de leur projet de fin d’étude, les étudiants de la formation
              « UX designer » de l’école multimédia dont Pierre et Quentin sont
              référents ont fait preuve d’un grand professionnalisme en
              produisant un travail de qualité, répondant aux enjeux de design
              UX complexes de notre plateforme de recherche de billets d’avion
              et de train.
              <br />
              <br />
              Nous avons particulièrement apprécié la méthodologie inculquée aux
              étudiants, centrée sur l’utilisateur avec une prise en compte de
              ses dimensions psychologique et émotionnelle qui s’est concrétisée
              par de nombreuses recherches et ateliers.”
            </p>
          }
        />

        <Quote
          className="mt-main"
          picture={
            <StaticImage
              src="../images/photos/Leslie.png"
              alt="photo de Leslie"
              className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
            />
          }
          nameAndTitle={
            <p>
              Leslie Matte
              <br />
              Consultante et formatrice en Design d’expérience (DX)&nbsp;:
              Ergonome expert, UX Design et Design Thinking
            </p>
          }
          quote={
            <p className="italic">
              “Avec Quentin et Pierre, nous travaillons ensemble régulièrement
              sur des sujets tels que des optimisations UX ou des projets
              d’enseignements a l’école multimedia. C’est un réel plaisir à
              chaque fois de partager nos points de vue avec un
              professionnalisme qui séduit systématiquement les clients.”
            </p>
          }
        />

        <Quote
          className="mt-main"
          picture={
            <StaticImage
              src="../images/photos/sandrine.png"
              alt="photo de Sandrine"
              className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
            />
          }
          nameAndTitle={
            <p>
              Sandrine Lavandier
              <br />
              Consultante Marketing digital & Directrice pédagogique à l’École
              Multimédia
            </p>
          }
          quote={
            <p className="italic">
              "Depuis plusieurs années je travaille avec Pierre Minelli et
              Quentin Ledoux, dans le cadre de leur fonction de professeurs
              référents de la filière UX à l’Ecole Multimédia. Cette
              collaboration est, sous plusieurs aspects, très stimulante et
              enrichissante. Outre leur savoir-faire et connaissances
              indéniables dans leur domaine, ils sont aussi force de proposition
              et pourvoyeurs de solutions. Ils m’accompagnent régulièrement dans
              les réflexions à mener pour garantir à nos apprenants le meilleur
              enseignement et suivi possible. Toujours dans un objectif commun
              de s’améliorer en permanence, ils sont une vraie valeur ajoutée
              pour l’école."
            </p>
          }
        />

        <Quote
          className="mt-main"
          picture={
            <StaticImage
              src="../images/photos/marco.png"
              alt="photo de Marco"
              className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
            />
          }
          nameAndTitle={
            <p>
              Marco Pellegrini
              <br />
              Lead UX Designer, Designing World Class Using Experiences &
              Référent UX Design chez Webstart
            </p>
          }
          quote={
            <p className="italic">
              "En tant que professeur coordinateur de la formation UX Design à
              l’école Webstart, j’ai le plaisir de travailler avec Pierre pour
              développer les compétences des élèves liées à la modération
              d’ateliers et à l’ideation. Avec Pierre, nous avons construit un
              nouveau module qui est déjà un succès pour les étudiants. Ensemble
              nous avons réussi à mettre en place un concept d’expérience
              pédagogique qui est très profitable à l’école et aux étudiants. Sa
              posture et son approche toujours très attentive à l’humain font de
              lui un pédagogue très apprécié par les étudiants."
            </p>
          }
        />
        <Button
          variant={ButtonVariant.secondary}
          text="Demander une formation"
          onClick={() => setShowModal(true)}
          className="mx-auto mt-main"
        />
      </Section>
    </Layout>
  );
};

export default Formations;
